<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="token.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $tc('token.title', 1) }}</v-subheader>
          <v-card>
            <v-card-text>
              <!-- @TODO: the autocomplete itemText requires camel- instead of kebab-case due to
                          an overwrite in the mixin -->
              <ClientAutocomplete field="clientName" v-model="values.client"/>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import Token from '@/application/models/token.js';
import ClientAutocomplete from '@/components/autocompletes/Client.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';

export default {
  name: 'TokenForm',
  extends: Form,
  components: {
    ClientAutocomplete,
    KFieldGroup,
  },
  data: () => ({
    values: new Token(),
  }),
};
</script>
